import React from "react";
import { SectionHeader } from "../../Headings";

function TestimonialsBlock(props) {
  const { heading, typeHandle } = props;

  return (
    <div className="container mx-auto mt-12 md:mt-16 lg:mt-24"  data-block={typeHandle}>
      <div>
        <SectionHeader heading={heading} />
      </div>

      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="12"
        data-height="375"
        data-nofollow="true"
        data-expandifr="true"
        data-scale="100"
        data-primary-color="#EB4D8A"
        data-secondary-color="#EB4D8A"
        data-reviews="1983377,1982775,1971932,1966230,1880714,1817742,1728035,1566101,1563879,1561361,1559727,1423827"
        data-clutchcompany-id="1019293"
      ></div>
    </div>
  );
}

export default TestimonialsBlock;
