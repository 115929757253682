import React, { useCallback } from 'react';
import Link from 'gatsby-link';

//Icons
import {FaAngleDoubleRight} from 'react-icons/fa';

const ServiceCard = ({image, tileImage, heading, href, blurb, underlineColour, ...props}) => {

  const borderColorClasses = useCallback(() => {
    switch (underlineColour) {
      case 'orange':
        return 'from-pink to-orange';
      case 'blue':
        return 'from-blue to-purple';
      default:
        return 'from-gray-900 to-gray-700';
    }
  },[underlineColour]);

  return (
    <article className="items-start justify-start gap-4 mx-auto text-black lg:items-center">
        <h5 className="relative pb-2 mt-4 mb-4 font-bold text-gray-700">
          <Link to={`/${href}`} className="flex w-full gap-4">
            {heading}
            <span className="pl-2 ml-auto">
              <FaAngleDoubleRight color="#242424" className="inline text-transparent bg-gradient-to-r from-orange to-pink bg-clip-text" size="20px" />
            </span>
          </Link>
          <span className={`block absolute bottom-0 w-full h-1 bg-gradient-to-r  ${borderColorClasses()}`}></span>
        </h5>
        <div className="text-base font-medium text-gray-600 line-clamp-4" dangerouslySetInnerHTML={{__html: blurb}} />
    </article>
  );
};

export default ServiceCard;
