import React from 'react';
import {SectionHeader} from '../components/Headings';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import {useInView} from 'react-intersection-observer';
import loadable from '@loadable/component';

import Loading from 'components/Loading';

const ReactPlayer = loadable(() => import('react-player'));

//Icons
import {FaAngleDoubleRight} from 'react-icons/fa';

const VideoBlock = ({heading, description, url, videoFallback, cta, ctaLink}) => {
  const [ref, inView] = useInView({triggerOnce: true});

  return (
    <div className="video-block pt-8 md:pt-4 pb-8 text-white" ref={ref}>
      <div className="max-w-screen-xl m-auto w-full">
        <div className="mb-12 md:mt-8 md:w-5/6 m-auto">
          <SectionHeader light heading={heading} subheading={description} />
        </div>
        <div className="player-wrapper">
          {inView ? <ReactPlayer fallback={<Loading />} className="react-player" url={url} width="100%" height="100%" controls /> : videoFallback}
        </div>
        <div className="text-center text-xl md:text-2xl font-semibold text-white mt-10">
          <AnchorLink to={ctaLink} className="hover:underline underline-offset-4">
            {cta} <FaAngleDoubleRight className="inline" size="20px" />
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;
