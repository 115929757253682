import React from 'react';
import { SectionHeader } from "../../Headings";
import { Link } from 'gatsby';
import SmallCard from '../../SmallCard';

function ResourcesBlock(props) {
  const {
    resources,
    heading,
    typeHandle
  } = props;

  return (
    <section className="container max-w-screen-xl px-6 mx-auto mt-12 md:mt-16 lg:mt-24" data-block={typeHandle}>
      <SectionHeader heading={heading} className="text-center" />
      <div className="grid gap-6 pt-4 m-auto md:grid-cols-2 lg:grid-cols-3">
        {resources?.length > 0 && resources.map(item => (
          <Link key={item.id} to={`/${item?.resource[0]?.uri}`}>
            <SmallCard displayStacked tileImage={item.heroImage[0]?.localFile} heading={item.heading} blurb={item.shortDescription} />
          </Link>
        ))}
      </div>
    </section>
  );
}

export default ResourcesBlock;
