import React from 'react';
import QuoteMark from '../images/quotemark.svg';
import QuoteMarkBlue from '../images/quotemark-blue.svg';

const QuoteBlock = ({ quote, byline, align, fullWidth, speechMarksColour }) => {
  const ColouredQuoteMark = speechMarksColour === 'orangeGradient' || !speechMarksColour ? QuoteMark : QuoteMarkBlue;
  return (
    <div
      className={`flex mb-8 ${
        align === 'right' ? 'justify-end text-right' : 'justify-start'
      }`}>
      <div
        className={`${
          fullWidth ? 'w-full' : 'max-w-xl'
        } font-bold quote-block`}>
        <div className={`mb-4 ${align === 'right' ? 'flex justify-end' : ''}`}>
          <img src={ColouredQuoteMark} alt="Quote Mark" />
        </div>
        <h2
          className={`${
            fullWidth && 'lg:prose-2xl'
          } prose-2xl md:prose-3xl  leading-snug text-black`}>
          <span dangerouslySetInnerHTML={{__html: quote}} />
        </h2>
        <p className="pt-4 font-normal">{byline}</p>
      </div>
    </div>
  );
};

export default QuoteBlock;
