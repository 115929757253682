import React from 'react';
import Quote from '../../QuoteBlock';

function QuoteBlock(props) {
  const {
    caption,
    text,
    typeHandle,
    speechMarksColour,
  } = props;

  return (
    <section className="container grid max-w-screen-xl grid-cols-12 px-6 mx-auto mt-12 md:mt-16 lg:mt-24 lg:px-12" data-block={typeHandle}>
      <div className='col-span-12 lg:col-span-8 lg:col-start-2'>
        <Quote fullWidth quote={text} byline={caption} speechMarksColour={speechMarksColour} align="left" />
      </div>
    </section>
  );
}

export default QuoteBlock;
