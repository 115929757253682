import React from 'react';
import { SectionHeader } from '../../Headings';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function ArticleBlock(props) {
  const {
    heroBannerImage,
    workingWithUsHeading,
    workingWithUsBody,
    typeHandle
  } = props;

  return (
    <div className="px-6">
      <div className="max-w-screen-xl mx-auto mt-12 md:mt-16 lg:mt-24" data-block={typeHandle}>
        {heroBannerImage && heroBannerImage[0]?.localFile &&
          <div className="mb-8">
            <GatsbyImage image={getImage(heroBannerImage[0].localFile)} alt="" className="block mx-auto" />
          </div>
        }
        { workingWithUsHeading ? <SectionHeader heading={workingWithUsHeading} /> : null }
        { workingWithUsBody ? (
          <div
            className="mx-auto leading-8 prose-lg lg:prose-xl lg:leading-10"
            dangerouslySetInnerHTML={{__html: workingWithUsBody}}
          />
        ) : null }
      </div>
    </div>
  );
}

export default ArticleBlock;
