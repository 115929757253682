import React from 'react';
import { SectionHeader } from "../../Headings";
import ServiceCard from '../../ServiceCard';

function WhatWeDoBlock(props) {
  const {
    // id,
    heading,
    description,
    backgroundColour,
    underlineColour,
    ourExpertise,
    typeHandle
  } = props;

  return (
    <section className={`mt-12 md:mt-16 lg:mt-24 ${backgroundColour === 'offWhite' ? 'bg-gray-100 py-12 md:py-16 lg:py-20' : '' }`} data-block={typeHandle} data-bg={backgroundColour}>
      <div className="container max-w-screen-xl mx-auto">
        {/* TODO: Populate with updated backend data */}
        <SectionHeader heading={heading} subheading={description} />
        <div className="grid w-full max-w-screen-xl grid-cols-1 gap-4 px-6 m-auto mt-8 text-black lg:mt-12 gap-y-8 md:grid-cols-2 md:gap-12 xl:pl-0 xl:pr-0">
          {ourExpertise.map(item => (
            <div className="max-w-md mx-auto" key={item.entry[0]?.id}>
              <ServiceCard underlineColour={underlineColour} heading={item.heading} blurb={item.description} href={item.entry[0]?.uri} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatWeDoBlock;
