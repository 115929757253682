import { Link } from 'gatsby';
import React from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { render } from 'react-dom';

function CtaBlock(props) {
  const {
    typeHandle,
    backgroundColour,
    callToActionButtonLabel,
    callToActionMailTo,
    callToActionEntry
  } = props;

  const bgColor = () => {
    switch (backgroundColour) {
      case 'offWhite':
        return 'bg-gray-100';
      case 'orangeGradient':
        return 'bg-gradient-to-r from-orange to-pink'
      case 'blueGradient':
        return 'bg-gradient-to-r from-blue to-purple'
      case 'white':
        return 'bg-white';
      default:
        return 'bg-white';
    }
  }

  const linkColor = () => {
    switch (backgroundColour) {
      case 'orangeGradient':
        return 'text-white'
      case 'blueGradient':
        return 'text-white'
      default:
        return 'bg-gradient-to-r from-orange to-pink bg-clip-text';
    }
  }

  const iconColor = () => {
    switch (backgroundColour) {
      case 'orangeGradient':
        return '#FFFFFF'
      case 'blueGradient':
        return '#FFFFFF'
      default:
        return '#EA4090';
    }
  }

  const renderCallToAction = () => {
    if (!callToActionButtonLabel) return null;

    if (callToActionMailTo) {
      return (
        <a
          href={`mailto:${callToActionMailTo}`}
          className={`inline text-base font-semibold tracking-wide text-left text-transparent cursor-pointer md:text-lg lg:text-2xl font-display ${linkColor()}`}>
          {callToActionButtonLabel}{' '}
          <span className="pl-2">
            <FaAngleDoubleRight
              color={iconColor()}
              className={`inline text-transparent`}
              size="20px"
            />
          </span>
        </a>
      );
    }

    if (callToActionEntry[0]) {
      const uri = callToActionEntry[0]?.uri;
      const title = callToActionEntry[0]?.title;
      const link = uri.startsWith('/') ? uri : `/${uri}`;

      return (
        <Link
          to={link}
          title={title}
          className={`inline text-base font-semibold tracking-wide text-left text-transparent cursor-pointer md:text-lg lg:text-2xl font-display ${linkColor()}`}>
          {callToActionButtonLabel}{' '}
          <span className="pl-2">
            <FaAngleDoubleRight
              color={iconColor()}
              className={`inline text-transparent`}
              size="20px"
            />
          </span>
        </Link>
      );
    }
  }

  return (
    <div className={`px-6 flex justify-center py-12 md:py-16 lg:py-24 mt-12 md:mt-16 lg:mt-24 ${bgColor()}`} data-block={typeHandle} data-bg={backgroundColour}>
      {renderCallToAction()}
    </div>
  );
}

export default CtaBlock;
