import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {FaAngleDoubleRight} from 'react-icons/fa';
import Loading from 'components/Loading';

export const ButtonPrimary = ({label, size, isDisabled, isLoading, type, style}) => {
  const LargeButton =
    'bg-white relative inline-flex shadow-md text-center min-w-0 py-3 tracking-wide px-10 text-gray-600 font-medium rounded-full justify-center font-display hover:shadow-lg hover:text-black transition duration-400 ease-in-out cursor-pointer focus:outline-none';
  const SmallButton =
    'bg-white relative inline-flex shadow-md text-center min-w-0 py-1 tracking-wide text-sm text-gray-600 px-5 rounded-full font-medium justify-center font-display hover:shadow-lg hover:text-black transition duration-400 ease-in-out cursor-pointer focus:outline-none';

  return (
    <button
      type={type}
      style={style}
      className={`${size === 'large' ? LargeButton : SmallButton} ${(isDisabled || isLoading) && 'pointer-events-none opacity-80'}`}
      disabled={isDisabled || isLoading}>
      {isLoading && <Loading />}
      <motion.span animate={{opacity: isLoading ? 0 : 1}} transition={{delay: isLoading ? 0 : 0.6, duration: 0.3}} className="pt-1">
        {label}
      </motion.span>
    </button>
  );
};

export const ButtonSecondary = ({label, icon, className, ...props}) => {
  return (
    <div
      className={`${className} group hover:bg-white hover:border-white transition-all hover:text-black text-white text-2xl border-gray-500 font-semibold font-display tracking-wide border inline-flex p-6 rounded-md max-w-md items-center cursor-pointer gap-2`}>
      <span dangerouslySetInnerHTML={{__html: label}} />
      { icon ? <GatsbyImage image={getImage(icon.localFile)} className="w-24 pointer-events-none flex-0" alt="Button icon" />: null }
      <div className="flex-0"><FaAngleDoubleRight size="40px" /></div>
    </div>
  );
};

ButtonPrimary.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
};

ButtonPrimary.defaultProps = {
  size: 'large',
  onClick: undefined,
};
