import React from 'react';
import { TwoColBlock } from '../../ContentBlocks';

function TextAndImageVideoBlock(props) {
  const {
    embedUrl,
    heroImage,
    heroImageAlt,
    text,
    typeHandle,
    backgroundColour,
  } = props;

  const bgClasses = () => {
    const padding = 'py-12 md:py-20';
    switch (backgroundColour) {
      case 'white':
        return 'bg-white';
      case 'offWhite':
        return 'bg-gray-100 ' + padding;
      case 'blueGradient':
        return 'text-white bg-gradient-to-r from-blue to-purple ' + padding;
      case 'orangeGradient':
        return 'text-white bg-gradient-to-r from-orange to-pink ' + padding;
      default:
        return 'bg-white';
    }
  };

  return (
    <section className={`px-6 mt-12 md:mt-16 lg:mt-24 ${bgClasses()}`} data-block={typeHandle} data-bg={backgroundColour}>
      <div className="container max-w-screen-xl mx-auto">
        <TwoColBlock content={text} video={embedUrl} image={heroImage[0]} imageAlt={heroImageAlt ?? ''} />
      </div>
    </section>
  );
}

export default TextAndImageVideoBlock;
