import {useState, useCallback} from 'react';
import useTimeout from './useTimeout';

const useDelay = ms => {
  const [resolved, setResolved] = useState(false);
  const complete = useCallback(() => setResolved(true), [setResolved]);

  useTimeout(complete, ms);
  return resolved;
};

export default useDelay;
