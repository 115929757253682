import React from 'react';
import { SingleContentBlock } from '../../ContentBlocks';
import { SectionHeader } from '../../Headings';

function ProcessBlock(props) {
  const {
    process,
    heading,
    typeHandle
  } = props;

  return (
    <section className="container px-6 mx-auto mt-12 md:mt-16 lg:mt-24" data-block={typeHandle}>
      {process?.length > 0 && (
        <div>
          {heading && (
            <div className="mb-12"><SectionHeader heading={heading} /></div>
          )}
          <div className="grid max-w-screen-xl gap-6 mx-auto mt-4 md:grid-cols-2">
            {process.map(({id, heading, description, imageAlt, image: [image], entry: [{uri: readMoreLink} = {}]}) => (
              <SingleContentBlock
                key={id}
                image={image}
                imageAlt={imageAlt ?? heading}
                heading={heading}
                content={description}
                readMoreLink={readMoreLink}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default ProcessBlock;
