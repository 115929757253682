import React from 'react';
import VideoBlock from '../../VideoBlock';
import {GatsbyImage} from 'gatsby-plugin-image';

function LargeVideoBlock(props) {
  const {
    heading,
    description,
    embedUrl,
    ctaText,
    callToActionUrl,
    placeholderImage,
    placeholderImageAlt,
    typeHandle
  } = props;
  return (
    <section className="mt-12 md:mt-16 lg:mt-24" data-block={typeHandle}>
      <VideoBlock
        heading={heading}
        description={description}
        url={embedUrl}
        cta={ctaText}
        ctaLink={callToActionUrl}
        videoFallback={
          placeholderImage.url ? (
            <GatsbyImage
              src={placeholderImage.url}
              className="absolute top-0 left-0"
              width={1280}
              height={720}
              alt={placeholderImageAlt || ""}
            />
          ) : null
        }
      />
    </section>
  );
}

export default LargeVideoBlock;
