import React from 'react';
import * as styles from './Wysiwyg.module.css'

function WysiwygBlock(props) {
  const {
    wysiwygContent,
    typeHandle,
    backgroundColour
  } = props;

  const bgClasses = () => {
    const padding = 'py-12 md:py-20';
    switch (backgroundColour) {
      case 'white':
        return 'bg-white';
      case 'offWhite':
        return 'bg-gray-100 ' + padding;
      case 'blueGradient':
        return 'text-white bg-gradient-to-r from-blue to-purple ' + padding;
      case 'orangeGradient':
        return 'text-white bg-gradient-to-r from-orange to-pink ' + padding;
      default:
        return 'bg-white';
    }
  };

  const copyColour = () => {
    switch (backgroundColour) {
      case 'blueGradient':
      case 'orangeGradient':
        return styles.wysiwygCopy__white;
      default:
        return '';
    }
  }

  return (
    <section className={`pl-6 pr-6 mx-auto mt-12 md:mt-16 lg:mt-24 ${bgClasses()}`} data-block={typeHandle} data-bg={backgroundColour}>
      <div className="max-w-screen-lg mx-auto prose md:prose-lg">
        <div dangerouslySetInnerHTML={{__html: wysiwygContent}} className={copyColour()} />
      </div>
    </section>
  );
}

export default WysiwygBlock;
