import {useRef, useCallback, useEffect} from 'react';

const useTimeout = (callback, ms) => {
  const timeoutRef = useRef();
  const cancel = useCallback(() => {
    const timeout = timeoutRef.current;
    if (timeout) {
      timeoutRef.current = undefined;
      clearTimeout(timeout);
    }
  }, [timeoutRef]);

  useEffect(() => {
    timeoutRef.current = setTimeout(callback, ms);
    return cancel;
  }, [callback, ms, cancel]);

  return cancel;
};

export default useTimeout;
