import React from 'react';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import {Carousel} from 'react-responsive-carousel';
import {SectionHeader} from '../../Headings';
import {CarouselCard} from '../../CarouselCard';

function CaseStudiesBlock(props) {
  const {
    heading = 'Case Studies',
    caseStudies,
    typeHandle
  } = props;

  const {isLarge} = useBreakpoint();

  return (
    <section className="relative z-10 mt-12 md:mt-16 lg:mt-24" data-block={typeHandle}>
      <div className="">
        <SectionHeader heading={heading} />
        <Carousel
          autoPlay
          centerMode={isLarge}
          infiniteLoop
          centerSlidePercentage={100}
          showThumbs={false}
          showArrows={false}
          interval={8000}
          showStatus={false}
        >
          {caseStudies?.length > 0 && caseStudies.map(({id, heading, highlightedHeading, shortDescription, readMoreLabel, caseStudy: [caseStudy], heroImage: [heroImage]}) => (
            <CarouselCard
              key={id}
              label={heading}
              highlighted_text={highlightedHeading}
              blurb={shortDescription}
              cta={readMoreLabel}
              ctaLink={`/${caseStudy?.uri}`}
              heroImage={heroImage}
              tags={caseStudy?.tags}
            />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default CaseStudiesBlock;
