import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import {FaAngleDoubleRight} from 'react-icons/fa';

export const TwoColBlock = ({content, video, image, imageAlt}) => {
  const hasMedia = video || image;

  const renderMedia = () => {
    if (video) {
      return (
        <div className="relative w-full video-block" style={{aspectRatio: '16 / 9'}}>
          <ReactPlayer
            url={video}
            controls className="absolute top-0 left-0 video-react"
            fallback={image?.localFile ? <GatsbyImage image={getImage(image.localFile)} alt={imageAlt} /> : null}
            />
        </div>
      );
    } else if (image) {
      return (
        <div className="w-full">
          <GatsbyImage image={getImage(image.localFile)} alt={imageAlt} />
        </div>
      );
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className={`${hasMedia ? 'max-w-screen-xl' : 'max-w-screen-lg'} mx-auto`}>
        <div className={`grid ${ hasMedia ? 'lg:grid-cols-2 gap-x-8' : ''} gap-y-12`}>
          <div
            className="pt-8 lg:pt-0 leading-8 prose-lg lg:prose-xl lg:leading-10"
            dangerouslySetInnerHTML={{__html: content}}
          />
          {renderMedia()}
        </div>
      </div>
    </div>
  );
};

export const SingleContentBlock = ({heading, content, transparent, image, imageAlt, readMoreLink}) => {
  return (
    <div className={`bg-white rounded-lg p-8 ${transparent ? 'bg-opacity-20 text-white shadow-sm' : 'shadow-xl'} ${image ? 'flex' : ''}`}>
      {image && (
        <div className="w-4/12 pr-8">
          <GatsbyImage image={getImage(image.localFile)} alt={imageAlt} />
        </div>
      )}
      <div className="w-full">
        <h4 className="mb-2 font-semibold">{heading}</h4>
        <div className="font-normal prose-lg" dangerouslySetInnerHTML={{__html: content}} />
        {readMoreLink && (
          <Link to={`/${readMoreLink}`} className="inline-block mt-6 font-semibold prose-lg hover:underline">
            Read more&nbsp;
            <FaAngleDoubleRight size={14} className="inline bg-clip-text" />
          </Link>
        )}
      </div>
    </div>
  );
};
