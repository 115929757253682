import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function FullWidthScreenshotBlock(props) {
  const {
    screenshot: [screenshot],
    screenshotAlt,
    typeHandle
  } = props;

  return (
    <>
      {screenshot && (
        <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto mt-12 overflow-hidden md:mt-16 lg:mt-24" data-block={typeHandle}>
          <GatsbyImage layout="fullWidth" image={getImage(screenshot.localFile)} alt={screenshotAlt} />
        </div>
      )}
    </>
  );
}

export default FullWidthScreenshotBlock;
