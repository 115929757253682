import React from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

//Icons
import {FaAngleDoubleRight} from 'react-icons/fa';

const SmallCard = ({image, tileImage, heading, blurb, alt, displayStacked, ...props}) => {
  return (
    <div
      className={`${
        displayStacked ? 'block' : 'flex'
      } items-start lg:items-center cursor-pointer justify-start text-black gap-4 rounded-md xl:block xl:pr-6 xl:border-r`}>
      {image && (
        <div className="items-center flex-shrink-0 xl:w-full h-12 lg:h-28">
          <GatsbyImage image={getImage(image)} alt={alt ?? heading} className="pr-4 pt-4 pb-4 h-auto w-16 lg:w-24 items-center" />
        </div>
      )}
      {tileImage && (
        <div className="overflow-hidden rounded">
          <GatsbyImage
            image={getImage(tileImage)}
            alt={alt ?? heading}
            className="w-full object-cover h-52 rounded transform hover:scale-105 transition flex"
          />
        </div>
      )}
      <div className="flex-initial mt-4">
        <h5 className="font-bold inline-block mb-1 text-gray-700">
          {heading}
          <span className="pl-2">
            <FaAngleDoubleRight color="#242424" className="inline bg-gradient-to-r from-orange to-pink text-transparent bg-clip-text" size="20px" />
          </span>
        </h5>
        <div className="text-base font-medium text-gray-600 line-clamp-4" dangerouslySetInnerHTML={{__html: blurb}} />
      </div>
    </div>
  );
};

export default SmallCard;
