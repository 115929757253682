import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

import useDelay from 'helpers/useDelay';

const Loading = ({delay}) => {
  const show = useDelay(delay);

  return <motion.span key="loader" className="loader" animate={{opacity: show ? 1 : 0}} exit={{opactiy: 0}} transition={{duration: 0.6}} />;
};

Loading.propTypes = {
  delay: PropTypes.number,
};

Loading.defaultProps = {
  delay: 300,
};

export default Loading;
