/**
 * @component PageBuilder.js
 * @desc Renders a matrix of content blocks
 * @param {array} blocks - An array of content blocks
 * @param {boolean} disableBottomPadding - Force the removal of bottom padding of the page builder (otherwise determined by the last block's background colour)
 * @returns JSX.Element
 */

import React from 'react';
import { useCallback } from 'react';

import ArticleBlock from './blocks/ArticleBlock';
import CaseStudiesBlock from './blocks/CaseStudiesBlock';
import CtaBlock from './blocks/CtaBlock';
import FullWidthScreenshotBlock from './blocks/FullWidthScreenshotBlock';
import KeypointsBlock from './blocks/KeypointsBlock';
import LogosBlock from './blocks/LogosBlock';
import LargeVideoBlock from './blocks/LargeVideoBlock';
import ProcessBlock from './blocks/ProcessBlock';
import QuoteBlock from './blocks/QuoteBlock';
import ResourcesBlock from './blocks/ResourcesBlock';
import TestimonialsBlock from './blocks/TestimonialsBlock';
import TextAndImageVideoBlock from './blocks/TextAndImageVideoBlock';
import WhatWeDo from './blocks/WhatWeDo';
import WysiwygBlock from './blocks/WysiwygBlock';
import * as styles from './PageBuilder.module.css';

function PageBuilder(props) {
  const {
    blocks,
    disableBottomPadding = false
  } = props;
  if(!blocks || !blocks.length) return null;

  const shouldPadBottom = () => {
    if(disableBottomPadding) return false;

    const lastBlock = blocks[blocks.length - 1];
    if(!lastBlock) return false;

    switch (lastBlock.backgroundColour) {
      case 'white':
        return true;
      default:
        return false;
    }
    
  }

  const renderBlocks = useCallback(() => {
    return blocks.map((block, index) => {
      switch(block.typeHandle) {
        case 'article':
          return <ArticleBlock key={index} {...block} />;
        case 'caseStudies':
          return <CaseStudiesBlock key={index} {...block} />;
        case 'cta':
          return <CtaBlock key={index} {...block} />;
        case 'fullWidthScreenshot':
          return <FullWidthScreenshotBlock key={index} {...block} />;
        case 'keypoints':
          return <KeypointsBlock key={index} {...block} />;
        case 'logos':
          return <LogosBlock key={index} {...block} />;
        case 'largeVideo':
          return <LargeVideoBlock key={index} {...block} />;
        case 'process':
          return <ProcessBlock key={index} {...block} />;
        case 'quote':
          return <QuoteBlock key={index} {...block} />;
        case 'resources':
          return <ResourcesBlock key={index} {...block} />;
        case 'testimonials':
          return <TestimonialsBlock key={index} {...block} />;
        case 'textAndImageVideo':
          return <TextAndImageVideoBlock key={index} {...block} />;
        case 'whatWeDo':
          return <WhatWeDo key={index} {...block} />;
        case 'wysiwyg':
          return <WysiwygBlock key={index} {...block} />;
        default:
          return null;
      }
    });
  }, [blocks]);

  return (
    <div className={`${styles.pageBuilder} ${shouldPadBottom() ? 'pb-12 md:pb-16 lg:pb-20' : ''} bg-white pt-12 md:pt-16 lg:pt-20`}>
      {renderBlocks()}
    </div>
  );
}

export default PageBuilder;
