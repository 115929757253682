import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function LogosBlock(props) {
  const {
    heading,
    logos,
    backgroundColour,
    typeHandle
  } = props;

  return (
    <div className={`px-6 ${backgroundColour === 'offWhite' ? 'bg-gray-100 py-12 md:py-20' : 'mt-12 md:mt-16 lg:mt-24'}`} data-block={typeHandle} data-bg={backgroundColour}>
      { heading ? <h4 className="mb-8 font-bold text-center text-gray-500">{heading}</h4> : null }
      <div className="container flex flex-wrap justify-center max-w-screen-xl gap-12 mx-auto">
        {logos?.map(partner => (
          <a
            key={partner?.id}
            href={partner?.partnerLink}
            rel="noreferrer"
            target="_blank"
            className="cursor-pointer">
            <GatsbyImage
              image={getImage(partner?.logo[0]?.localFile)}
              alt={partner?.partnerName || 'Partner Logo'}
              className="w-32 h-24 transition opacity-50 filter-grayscale hover:filter-none hover:opacity-100"
              imgClassName="h-24 w-32"
              imgStyle={{objectFit: 'contain'}}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default LogosBlock;
