import React from 'react';
import {motion} from 'framer-motion';
import {Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

//Icons
import {FaAngleDoubleRight} from 'react-icons/fa';

import {ButtonPrimary} from '../components/Button';

export const CarouselCard = ({label, highlighted_text, heroImage, blurb, cta, ctaLink, alt, tags}) => {
  return (
    <div className="mx-auto max-w-screen-2xl px-6 md:px-12">
      <div className="relative grid grid-cols-12 m-auto font-display isolate">
        {/* Image */}
        <div className="col-span-12 md:row-start-1 md:col-end-8 md:col-span-6 md:col-start-1 lg:col-start-2">
          {heroImage?.localFile ? (
            <GatsbyImage image={getImage(heroImage.localFile)} alt={alt ?? label} className="w-full h-full rounded-md -z-1" />
            ) : null}
        </div>

        {/* Text */}
        <div className="flex flex-col col-span-12 p-5 -translate-y-10 md:shadow-xl md:row-start-1 md:col-start-7 md:my-6 lg:col-end-12 md:p-8 lg:p-10 bg-actualWhite rounded-br-md md:rounded-md self-center">
          <h3 className="mb-4 text-xl font-semibold leading-snug tracking-wide text-left sm:text-2xl font-display md:leading-9 md:text-3xl md:mb-10">
            {label}{" "}
            <span className="inline-block font-bold text-transparent font-display bg-gradient-to-r from-orange to-pink bg-clip-text">{highlighted_text}</span>
          </h3>

          <div style={{ minHeight: "5rem" }} className="max-w-xl text-base font-medium text-left text-gray-600 md:text-lg" dangerouslySetInnerHTML={{__html: blurb}}></div>

          <div className="flex gap-3 mt-4 md:mt-8">
            {tags?.map(({id, title}) => (
              <ButtonPrimary key={id} label={title} size="small" style={{cursor: 'default'}} />
              ))}
          </div>

          <div className="mt-auto">
            <hr className="mt-4 mb-2 md:mb-4 md:mt-6"></hr>
          </div>

          <div className="flex">
            <Link
              to={ctaLink}
              className="inline text-base font-semibold tracking-wide text-left text-transparent cursor-pointer md:text-lg font-display bg-gradient-to-r from-orange to-pink bg-clip-text">
              {cta}{' '}
              <span className="pl-2">
                <FaAngleDoubleRight color="#EA4090" className="inline text-transparent bg-gradient-to-r from-orange to-pink bg-clip-text" size="20px" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
