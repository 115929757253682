import React from 'react';
import {SectionHeader} from 'components/Headings';
import {SingleContentBlock} from 'components/ContentBlocks';
import {Link} from 'gatsby';
import {FaAngleDoubleRight} from 'react-icons/fa';

function KeypointsBlock(props) {
  const {
    keypoints,
    heading,
    callToActionButtonLabel,
    callToActionEntry,
    callToActionMailTo,
    typeHandle,
    backgroundColour
  } = props;

  function renderCallToAction(){
    const ctaTextColor = backgroundColour === 'orangeGradient' || backgroundColour === 'blueGradient' ? 'text-white' : 'bg-gradient-to-r from-orange to-pink bg-clip-text';
    const ctaIconColor = backgroundColour === 'orangeGradient' || backgroundColour === 'blueGradient' ? '#FFFFFF' : '#EA4090';

    if(callToActionMailTo){
      return (
        <a href={`mailto:${callToActionMailTo}`}
          className={`inline text-base font-semibold tracking-wide text-left text-transparent cursor-pointer md:text-lg lg:text-2xl font-display ${ctaTextColor}`}>
          {callToActionButtonLabel}{' '}
          <span className="ml-2">
            <FaAngleDoubleRight className={`inline text-transparent`} color={ctaIconColor} size="20px" />
          </span>
        </a>
      );
    }

    if(callToActionEntry[0]?.uri){
      const uri = callToActionEntry[0]?.uri;
      const link = uri.startsWith('/') ? uri : `/${uri}`;

      return (
        <Link
          to={link}
          className={`inline text-base font-semibold tracking-wide text-left text-transparent cursor-pointer md:text-lg lg:text-2xl font-display ${ctaTextColor}`}>
          {callToActionButtonLabel}{' '}
          <span className="ml-2">
            <FaAngleDoubleRight className={`inline text-transparent`} color={ctaIconColor} size="20px" />
          </span>
        </Link>
      );
    }

    return null;
  };

  function getBackground(){
    switch(backgroundColour){
      case 'orangeGradient':
        return 'bg-gradient-to-r from-orange to-pink py-8 md:py-12 lg:py-24';
      case 'blueGradient':
        return 'bg-gradient-to-r from-blue to-purple py-8 md:py-12 lg:py-24';
      case 'white':
        return 'bg-white';
      case 'offWhite':
        return 'bg-gray-100 py-8 md:py-12 lg:py-24';
      default:
        return '';
    }
  }

  return (
    <section data-block={typeHandle} className={`px-6 mt-12 md:mt-16 lg:mt-24 ${getBackground()}`}>
      <div className="container max-w-screen-xl mx-auto" data-block={typeHandle}>
        { heading && (
          <div className="mb-8 md:mb-12">
            <SectionHeader heading={heading} light={backgroundColour === 'blueGradient' || backgroundColour === 'orangeGradient'}/>
          </div>
        )}
        {keypoints?.length > 0 && (
          <>
            <div className="grid max-w-screen-xl gap-6 mx-auto md:grid-cols-2">
              {keypoints.map((keypoint, i, {length}) => (
                <SingleContentBlock
                  key={keypoint.id}
                  heading={keypoint.keypointTitle}
                  content={keypoint.keypointContent}
                  transparent={backgroundColour === 'blueGradient' || backgroundColour === 'orangeGradient'} />
                ))}
            </div>
          </>
        )}

        { callToActionButtonLabel ? (
            <div className="flex justify-center w-full mt-8 md:mt-12">
              {renderCallToAction()}
            </div>
          ) : null
        }
      </div>
    </section>
  );
}

export default KeypointsBlock;
